const shellMessages = {
    
    // UI
    accountYour: "Mes informations",
    cancel: "Annuler",
    change: "changer",
    changeTheme: "Changer le thème",
    changeYourName: "Changer votre pseudo",
    changeYourPassword: "Changer le mot de passe",
    close: "Fermer",
    confirm: "Confirmer",
    dark: "sombre",
    delete: "Effacer",
    emailCheck: "Vérifier votre boîte mail",
    emailCheckSpam: "Si le message n’apparaît pas dans votre boîte de réception, recherchez-le dans vos autres dossiers. Si un filtre de courrier indésirable ou une règle de messagerie a déplacé l’e-mail, celui-ci peut se trouver dans le dossier Spam, Indésirable, Corbeille, Éléments supprimés ou Archive",
    invalidCredentials: "Identifiants invalides",
    itemDeleteConfirmation:  "Êtes-vous sûr de supprimer ",
    language: "Langue | Langues",
    light: "clair",
    login: "Identifiant | Identifiants",
    lostPassword: "Mot de passe perdu",
    name: "Nom",
    next: "suivant",
    organization: "Organisation",
    password: "mot de passe",
    passwordCurrentConfirm: "Entrez mot de passe actuel",
    passwordDifferent: "Votre nouveau mot de passe doit être différent de l'actuel",
    passwordHint: "Au moins {count} caractères",
    password_invalid: "Le mot de passe actuel entré est non valide, essayez une nouvelle fois",
    passwordLinkOutdated: "Ce lien n'est plus valide. Veuillez recommencer.",
    passwordNewConfirm: "Nouveau mot de passe",
    passwordReinitialize: "Réinitialiser mon mot de passe",
    passwordReset: "Entrer l'email correspondant à votre identifiant",
    passwordResetMail: "Une demande de confirmation vous a été envoyée à l'adresse suivante",
    password_updated: "Votre mot de passe a été actualisé",
    policyCookies: "Politique relative aux cookies",
    policyLegalDisclaimer: "Mentions Légales",
    policyPrivacy: "Politique de confidentialité",
    policyTermsConditions: "Conditions d'utilisations",
    previous: "retour",
    profiles: "Profiles",
    requiredFields: "* Champ obligatoire",
    role: "Role",
    save: "Sauvegarder",
    search : "rechercher",
    searchInTitle: "Rechercher dans le titre",
    signin: "Connexion",
    signout: "Se Déconnecter",
    success: "validé",
    subscriptions: "Abonnements Pays",
    theme: "apparence",
    update: "Mettre à jour",
    user: "utilisateur | utilisateurs",
    users: "Utilisateurs",  // needed for drawer
    userNew: "Nouvel utilisateur",
    userEdit: "Modifier utilisateur",
    
}

// 👇️ default export
export default shellMessages;