const messages = {

    // Roles
    jjmaster: "admin",
    admin: "Admin_fr",
    trainer_subscriber: "Entraîneur Abonné",
    trainer: "Trainer",
    deck: "Deck",
    subscriber: "Abonné",
    reader: "Lecteur",
    slaver: "Slaver",
    tagger: "Labels",
    labeller: "Labelling",
    publisher: "Publisher",
    canvas: "Canvas",


    // NAV LIST
    Drawer: {
        home: 'Accueil',
        sources: "Sources",
        dashboard: "Tableau de bord",
        classifiers: "Classificateurs",
        settings: 'Paramètres',
        packages: "Packages",
        packages_sets: "Packages Sets",
        trans_sets: "Traduction Sets",
        manager: "Manager",
        tools: "Outils",
        engine: "Moteur",
        logs: "Logs",
        tags: "Tags",
        blender: "Blender",
    },

    // sources
    sources: "Sources",
    sourcesNew: "Nouvelle Source",
    sourcesEdit: "Modifier Source",
    sourceRef: "Référence (Ex: FR023)",
    sourcesLanguages: "Langues (Ex: fr/en/...)",
    sourcesFamily: "Famille (Ex: class1,class2,..)",
    sourcesAddurl: "Url prefix",
    sourcesFilterout: "ne pas garder si termes dans url ('term1,term2,...)",

    // project vocab
    country: "Pays | Pays",
    city: "Ville | Villes",
    description: "Description",
    done: "Fait | Faits",
    folders: "Mes Dossiers",
    news: "Actualités",
    noMorePost: "Aucun article",
    pool: "Favoris",
    remaining: "Restant | Restants",
    set: "set",
    standby: "En attente",
    subscription: "Abonnement",
    trash: "Corbeille",
    trashBatchButton: "Tous ces articles à la corbeille ?",
    newBatch: "nouveaux articles ?",

    // SEARCH
    search: "Rechercher",
    atLeast: "Au moins un de ces termes",
    allThese: "Tous ces termes",
    exact: "Phrase exacte",
    none: "Aucun de ces termes",
    postNumber: "Numéros d'articles",
    categories: "Catégories",
    noResult: "Aucun résutat",
    postFoundWith: "article(s) trouvés avec :",

    avatars: "Avatars",
    calendar: "Calendrier",
}

// 👇️ default export
export default messages;